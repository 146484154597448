<!--
 * @Description: 空组件
 * @Author: zhang zhen
 * @Date: 2023-04-21 15:34:36
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-02 16:48:14
 * @FilePath: /page-sass/src/components/plugins/EmptyArea.vue
-->
<template>
 <div class="empty-area" :class="needBorder && 'border'">
        <a-empty :image="simpleImage" :description="description"></a-empty>
      </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
    export default {
        name: 'EmptyArea',
        props: {
            needBorder: {
                type: Boolean,
                default: true
            },
            description: {
                type: String,
                default: '暂无数据'
            }
        },
        data() {
            return {
                simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            }
        },
    }
</script>

<style lang="less" scoped>
.empty-area {
  height: 450px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.border {
    border: 1px solid #f2f2f2;
  }
}
</style>